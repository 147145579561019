<template>
  <div>
    <!-- top -->
    <van-sticky>
      <van-nav-bar title="交易纪录" left-arrow @click-left="onClickLeft">
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <van-dropdown-menu>
      <van-dropdown-item v-model="value1" :options="option1" />
    </van-dropdown-menu>

    <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
    <van-cell-group v-if="value1 == 0">
      <van-cell
        :value="'¥' + deposit.amount"
        v-for="deposit in depositList"
        :key="deposit.order_id"
      >
        <template #title>
          <span
            class="custom-title"
            v-clipboard:copy="deposit.order_id"
            v-clipboard:success="onCopy"
            >订单号：
            <span>{{ deposit.order_id }}</span>
            <van-icon name="brush-o" size="20" class="search-icon" />
          </span>
        </template>
        <template #label>
          <p
            class="custom-title"
            v-clipboard:copy="deposit.bank_name"
            v-clipboard:success="onCopy"
          >
            银行名称：{{ deposit.bank_name }}
          </p>
          <p
            class="custom-title"
            v-clipboard:copy="deposit.bank_no"
            v-clipboard:success="onCopy"
          >
            卡号：{{ deposit.bank_no }}
          </p>
          <p
            class="custom-title"
            v-clipboard:copy="deposit.bank_owner"
            v-clipboard:success="onCopy"
          >
            户名：{{ deposit.bank_owner }}
          </p>
          <p
            class="custom-title"
            v-clipboard:copy="deposit.bank_owner"
            v-clipboard:success="onCopy"
          >
            状态：{{ deposit.status_ch }}
          </p>
        </template>
      </van-cell>
      <van-pagination
        v-model="depositPage"
        :page-count="depositPaginate.total_page"
      />
    </van-cell-group>
    <van-cell-group v-else>
      <van-cell
        :value="'¥' + deposit.amount"
        v-for="deposit in withdrawList"
        :key="deposit.order_id"
      >
        <template #title>
          <span
            class="custom-title"
            v-clipboard:copy="deposit.order_id"
            v-clipboard:success="onCopy"
            >订单号：
            <span>{{ deposit.order_id }}</span>
            <van-icon name="brush-o" size="20" class="search-icon" />
          </span>
        </template>
        <template #label>
          <p
            class="custom-title"
            v-clipboard:copy="deposit.bank_name"
            v-clipboard:success="onCopy"
          >
            银行名称：{{ deposit.bank_name }}
          </p>
          <p
            class="custom-title"
            v-clipboard:copy="deposit.bank_no"
            v-clipboard:success="onCopy"
          >
            卡号：{{ deposit.bank_no }}
          </p>
          <p
            class="custom-title"
            v-clipboard:copy="deposit.bank_owner"
            v-clipboard:success="onCopy"
          >
            户名：{{ deposit.bank_owner }}
          </p>
          <p
            class="custom-title"
            v-clipboard:copy="deposit.bank_owner"
            v-clipboard:success="onCopy"
          >
            状态：{{ deposit.status }}
          </p>
        </template>
      </van-cell>
      <van-pagination
        v-model="withdrawPage"
        :page-count="withdrawPaginate.total_page"
      />
    </van-cell-group>
    <!-- </van-pull-refresh> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      value1: 0,
      option1: [
        { text: this.$returnBuySel("出货") + "订单", value: 0 },
        { text: this.$returnBuySel("进货") + "订单", value: 1 },
      ],
      depositPaginate: {},
      depositList: [],
      depositPage: 1,
      withdrawList: [],
      withdrawPaginate: {},
      withdrawPage: 1,
      isLoading: false,
    };
  },
  watch: {
    value1() {
      this.withdrawPage = 1;
      this.depositPage = 1;
    },
    depositPage() {
      this.getDeposit();
    },
    withdrawPage() {
      this.getWithdraw();
    },
  },
  methods: {
    onCopy() {
      this.$toast.success("已复制");
    },
    onClickLeft() {
      this.$router.push({
        path: "ordersC",
        query: { getMethod: this.$route.query.getMethod },
      });
    },
    onRefresh() {
      this.$store.dispatch("overlayHandler", true);
      this.getDeposit();
      this.getWithdraw();
    },
    getDeposit() {
      this.$http
        .get(`/mobile/orders/completed?page=${this.depositPage}`)
        .then((res) => {
          if (res.data.code == 0) {
            this.depositList = res.data.data;
            this.depositPaginate = res.data.paginate;
            this.isLoading = false;
            this.$store.dispatch("overlayHandler", false);
          }
        });
    },
    getWithdraw() {
      this.$http
        .get(`/mobile/payments/completed?page=${this.withdrawPage}`)
        .then((res) => {
          if (res.data.code == 0) {
            this.withdrawList = res.data.data;
            this.withdrawPaginate = res.data.paginate;
            this.isLoading = false;
            this.$store.dispatch("overlayHandler", false);
          }
        });
    },
  },
  created() {
    this.getDeposit();
    this.getWithdraw();
  },
};
</script>

<style lang="scss" scoped>
.van-cell__title,
.van-cell__value {
  // flex: auto !important;
}
.van-cell__value {
  white-space: nowrap;
}
</style>
